import type { Options, ResponsePromise } from "ky";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import ky from "ky";
import { useSelector } from "./redux";
import { useMemoCompare } from "./memo";
import { ADMIN_SERVER_ROOT, SERVER_ROOT } from "../config";
import { StatusCodes } from "http-status-codes";

interface RequestState<T = any> {
  data?: T;
  error?: any;
  loading: boolean;
}

type SearchParams = Record<string, string | number | boolean>;

export type Requester<RequestQuery, RequestBody, ResponseDataType> =
  (options?: {
    route?: string;
    body?: RequestBody;
    searchParams?: RequestQuery;
  }) => Promise<ResponseDataType | null>;

type OnError = (error?: any) => void;
type OnSuccess<ResponseDataType> = (
  response: Awaited<ResponsePromise>,
  data: ResponseDataType
) => void;

// Allows default query and body values to be used
interface UseHttpRequestOptions<ResponseDataType>
  extends Omit<Options, "body" | "prefixUrl" | "searchParams"> {
  admin?: boolean;
  body?: AnyObject;
  searchParams?: SearchParams;
  onError?: OnError;
  onSuccess?: OnSuccess<ResponseDataType>;
}

/**
 * @description Hook that allows making authenticated HTTP REST requests to queue server
 * @param baseRoute Path on queue server
 * @param baseOptions Defaults to be used in request; can be overriden with options passed via requester
 */
export function useHttpRequest<
  RequestQuery extends SearchParams = SearchParams,
  RequestBody extends AnyObject = AnyObject,
  ResponseDataType = any
>(
  baseRoute: string,
  baseOptions: UseHttpRequestOptions<ResponseDataType>
): [
  requester: Requester<RequestQuery, RequestBody, ResponseDataType>,
  requestState: RequestState<ResponseDataType>
] {
  const { oktaAuth } = useOktaAuth();

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );
  const accountName = useSelector((state) => state.queue.network);
  const productionName = useSelector((state) => state.queue.currentShow);
  const episodeName = useSelector((state) => state.queue.currentEpisode);

  const memoBaseOptions = useMemoCompare(baseOptions);

  const [abortController, setAbortController] = useState<AbortController>();

  // create abort signal on mount
  useEffect(() => {
    // set the aborted flag on unmount
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  const authenticatedOptions: UseHttpRequestOptions<ResponseDataType> = useMemo(
    () => ({
      ...memoBaseOptions,
      hooks: {
        beforeRequest: [
          async (request) => {
            try {
              const { accessToken } = await oktaAuth.tokenManager.getTokens();
              let tokenStr = "";

              // Token has been removed due to expiration or error while renewing
              if (
                !accessToken ||
                oktaAuth.tokenManager.hasExpired(accessToken)
              ) {
                // Attempt manual renewal of tokens
                const { accessToken: renewedAccessToken } =
                  await oktaAuth.token.renewTokens();

                // Use the renewed access token
                tokenStr = renewedAccessToken.accessToken;
              } else {
                // Use the valid token
                tokenStr = accessToken.accessToken;
              }

              request.headers.set("Authorization", `Bearer ${tokenStr}`);
            } catch (err) {
              console.warn(
                "Failed to attach access token to authorization header.",
                err
              );
            }
          },
        ],
      },
      searchParams: {
        accountName,
        accountGuid,
        episodeName,
        productionName,
        productionGuid,
        ...(memoBaseOptions.searchParams ?? {}),
      },
    }),
    [
      accountName,
      accountGuid,
      episodeName,
      memoBaseOptions,
      oktaAuth,
      productionName,
      productionGuid,
    ]
  );

  const memoAuthenticatedOptions = useMemoCompare(authenticatedOptions);

  const [data, setData] = useState<ResponseDataType>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const requester: Requester<RequestQuery, RequestBody, ResponseDataType> =
    useCallback(
      async (requestOptions) => {
        const { admin, method, onError, onSuccess } = memoAuthenticatedOptions;

        setLoading(true);

        try {
          // GET and HEAD requests can't have a body
          const body: string =
            method !== "get" && method !== "head"
              ? JSON.stringify({
                  ...(memoAuthenticatedOptions.body ?? {}),
                  ...(requestOptions?.body ?? {}),
                })
              : undefined;

          const searchParams: SearchParams = {
            ...((memoAuthenticatedOptions.searchParams as SearchParams) ?? {}),
            ...(requestOptions?.searchParams ?? {}),
            appName: "queueManager",
          };

          const route = requestOptions?.route
            ? `${baseRoute}${requestOptions.route}`
            : baseRoute;

          const abortController = new AbortController();
          setAbortController(abortController);

          const response = await ky(route, {
            ...memoAuthenticatedOptions,
            prefixUrl: `${admin ? ADMIN_SERVER_ROOT : SERVER_ROOT}/`,
            headers: {
              "Content-Type": "application/json",
            },
            body,
            retry: 1,
            searchParams,
            signal: abortController.signal,
            timeout: 5000,
          });

          setLoading(false);

          let data: ResponseDataType;

          try {
            data = await response.json();
          } catch (err) {
            console.log(
              `Failed to parse json on response: ${err}; Response was:`
            );
            console.dir(response);
          }

          setData(data);

          onSuccess?.(response, data);

          return data;
        } catch (err) {
          if (err?.message?.includes("The operation was aborted. ")) {
            console.log("Request aborted on hook unmount.");
          } else {
            console.warn(err);
          }

          setData(null);
          setError(err);
          setLoading(false);

          if (err?.response?.status === StatusCodes.TOO_MANY_REQUESTS) {
            onError?.({
              message: "Too many requests; Please try again later.",
            });
          } else {
            onError?.(err);
          }

          // redirect to login on unauthorized request
          if (err?.response?.status === StatusCodes.UNAUTHORIZED) {
            oktaAuth.signOut();
          }

          return null;
        }
      },
      [baseRoute, memoAuthenticatedOptions, oktaAuth]
    );

  const requestState = {
    data,
    error,
    loading,
  };

  return [requester, requestState];
}
