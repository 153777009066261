import { Fragment, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { hoursToMilliseconds, secondsToMilliseconds } from "date-fns";
import { useDispatch, useSocket } from "../hooks";

import {
  setUserInfo,
  setRoleChoices,
  selectRole,
} from "../store/queue/actions";

import selectDefaultRole from "../utilities/selectDefaultRole";
import * as R from "ramda";

export default function AuthHandler(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const socketService = useSocket();
  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;

  const handleLogout = () => {
    //console.log("AuthHandler: Logging out in 10 seconds.");
    setTimeout(async () => {
      //console.log(`AuthHandler: Logging out as of ${new Date().toString()}`);
      await oktaAuth.signOut();
    }, secondsToMilliseconds(10));
  };

  const tokenManagerEvents = {
    added: (key, token) => {
      /*console.log(
        `AuthHandler: Token ${key} added at ${new Date().toString()}.`
      );*/
    },
    expired: async (key, token) => {
      /*console.log(
        `AuthHandler: Token ${key} expired at ${new Date().toString()}.`
      );*/
    },
    error: async (err) => {
      console.error(
        `AuthHandler: Token manager experienced critical error at ${new Date().toString()}:`
      );
      console.dir(err);
      console.error("THIS WILL TRIGGER A LOGOUT EVENT");
      handleLogout();
    },
    renewed: (key, newToken, oldToken) => {
      /*console.log(
        `AuthHandler: Token ${key} was renewed at ${new Date().toString()}.`
      );*/
    },
    removed: (key, token) => {
      /*console.log(
        `AuthHandler: Token ${key} was removed at ${new Date().toString()}.`
      );*/
    },
  };

  const addTokenListeners = () => {
    Object.keys(tokenManagerEvents).forEach((tokenEventName: any) => {
      oktaAuth.tokenManager.on(
        tokenEventName,
        tokenManagerEvents[tokenEventName]
      );
      /*console.log(
        `AuthHandler: Okta token event listener "${tokenEventName}" instantiated.`
      );*/
    });
  };

  const removeTokenListeners = () => {
    Object.keys(tokenManagerEvents).forEach((tokenEventName: any) => {
      oktaAuth.tokenManager.off(tokenEventName);
      /*console.log(
        `AuthHandler: Okta token event listener "${tokenEventName}" removed (if applicable).`
      );*/
    });
  };

  const onActive = () => {
    //console.warn("AuthHandler: onActive was called. It should not have been.");
  };

  const onIdle = async () => {
    /*console.log(
      `AuthHandler: Detected that user is idle as of ${new Date().toString()}.`
    );*/
    handleLogout();
  };

  useIdleTimer({
    debounce: 0,
    events: ["keydown", "mousedown", "MSPointerDown"],
    timeout: hoursToMilliseconds(2),
    onActive,
    onIdle,
  });

  useEffect(() => {
    const getUserInfo = async () => {
      //console.log("AuthHandler: Retrieving User info.");
      const userInfo = oktaAuth.getUser();
      return userInfo || null;
    };

    if (isAuthenticated) {
      //console.log("AuthHandler: User is authenticated.");
      addTokenListeners();
      //console.log("AuthHandler: Connecting to server.");
      socketService.init();

      getUserInfo().then((userInfo) => {
        if (userInfo) {
          /*console.log("AuthHandler: UserInfo has been retrieved.");
          console.dir(userInfo);*/

          const parsedUserInfo = parseNetworksForUser(userInfo);
          const roleChoices = parseRolesForUser(userInfo);

          dispatch(setUserInfo(parsedUserInfo));
          dispatch(setRoleChoices(roleChoices));

          const defaultRole = selectDefaultRole(roleChoices);
          dispatch(selectRole(defaultRole));

          //console.log("AuthHandler: User has been logged in.");
        } else {
          /*console.log(
            "AuthHandler: Critical error occurred during login: No User info has been retrieved."
          );*/
        }
      });
    }

    return () => {
      //console.log("AuthHandler: User is not authenticated.");
      removeTokenListeners();
    };
  }, [dispatch, isAuthenticated, oktaAuth, socketService]);

  useEffect(() => {
    if (isAuthenticated && pathname.startsWith("/login")) {
      history.push("/");
    }
  }, [history, isAuthenticated, pathname]);

  return <Fragment>{props.children}</Fragment>;
}

const desiredRoleChoices = [
  "Role_CallProducer",
  "Role_CallScreener",
  "Role_CallScheduler",
  "Role_CallBooker",
  "Role_HelpDesk",
  "Role_QueueAdmin",
  "Role_QueueTech",
  "Role_StageDoor",
  "Role_MultiboxController",
  "Role_AudienceQueue",
  "Role_PowerUser",
];

function parseNetworksForUser(userInfo) {
  const { groups } = userInfo;

  const networks = Object.values(groups)
    .filter((group: string) => group.includes("Network_"))
    .map((group: string) => group.replace("Network_", ""))
    .sort();

  // Place "VCC" at top of the list
  const index = networks.indexOf("VCC");
  if (index > -1) {
    networks.splice(index, 1);
    networks.unshift("VCC");
  }

  return { ...userInfo, networks };
}

function parseRoleName(_roleName) {
  let t = _roleName.replace("Role_", "");
  t = t.charAt(0).toLowerCase() + t.substr(1);
  return t;
}

function parseRole(roleName: string, userGroups: string[]) {
  const roleAlias = R.cond([
    [R.equals("Role_CallScheduler"), R.always("Call Scheduler")],
    [R.equals("Role_CallBooker"), R.always("Call Booker")],
    [R.equals("Role_HelpDesk"), R.always("Help Desk")],
    [R.equals("Role_QueueAdmin"), R.always("Queue Admin")],
    [R.equals("Role_QueueTech"), R.always("Queue Tech")],
    [R.equals("Role_CallScreener"), R.always("Call Screener")],
    [R.equals("Role_StageDoor"), R.always("StageDoor Manager")],
    [R.equals("Role_MultiboxController"), R.always("CrowdView Operator")],
    [R.equals("Role_AudienceQueue"), R.always("Audience Queue Operator")],
    [R.equals("Role_PowerUser"), R.always("Power User")],
    [R.T, R.always("Call Producer")],
  ])(roleName);

  if (userGroups.includes(roleName)) {
    return {
      name: parseRoleName(roleName),
      alias: roleAlias,
      value: true,
    };
  } else {
    return {
      name: parseRoleName(roleName),
      alias: roleAlias,
      value: false,
    };
  }
}

function parseRolesForUser(userInfo) {
  const userGroups = userInfo.groups.filter((groupName) =>
    groupName.startsWith("Role")
  );

  return desiredRoleChoices.map((roleChoice) =>
    parseRole(roleChoice, userGroups)
  );
}
