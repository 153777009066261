import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "../../../hooks/redux";
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import EndpointCard from "./EndpointCard";
import TooltipButton from "../../../components/TooltipButton";
import { sortStringsLikeHuman } from "../../../utilities/utilityFunctions";
import { parseMultiboxName } from "../../../utilities/utilityFunctions";
import { isAfterTwilioSoftShutoffDate } from "../../../utilities/definitions";
import { endpointList } from "./templates";
const { filterAndSort, noEndpoints } = endpointList;

function isCrowdViewControlPanel(name: string) {
  return name.toLocaleLowerCase().startsWith("multibox_");
}
const isPrescreenControlPanel = (name: string) => {
  return name.toLowerCase().includes("prescreen");
};

const DEFAULT_FILTERS = isAfterTwilioSoftShutoffDate
  ? ["transmission", "prescreen"]
  : ["transmission", "prescreen", "crowdview"];

type Props = {
  currentRole: any;
  roleChoices: any;
  parentFuncs: any;
};

const ControlPanelList: FunctionComponent<Props> = (props) => {
  const { currentRole, roleChoices, parentFuncs } = props;
  const controlPanels = useSelector((state) => state.queue.screeners);

  const [activeFilters, setActiveFilters] = useState(DEFAULT_FILTERS);

  const [hasControlPanels, setHasControlPanels] = useState(false);
  const [hasPrescreens, setHasPrescreens] = useState(false);
  const [hasCrowdViews, setHasCrowdViews] = useState(false);
  const [sortAlphabetically, setSortAlphabetically] = useState(true);

  const handleFilterChange = (selectedValue: string) => {
    let filters = [...activeFilters];

    if (filters.includes(selectedValue)) {
      filters.splice(filters.indexOf(selectedValue), 1);
    } else {
      filters.push(selectedValue);
    }

    setActiveFilters(filters);
  };

  const visibleControlPanels = useMemo(() => {
    const controlPanelNames = Object.keys(controlPanels);
    const cps = controlPanelNames
      .filter((name) => {
        const isPrescreen = isPrescreenControlPanel(name);
        const isCrowdView = isCrowdViewControlPanel(name);

        if (!isPrescreen && !isCrowdView) {
          return activeFilters.includes("transmission");
        } else {
          if (isPrescreen) {
            return activeFilters.includes("prescreen");
          }
          if (isCrowdView) {
            if (isAfterTwilioSoftShutoffDate) {
              return false;
            } else {
              return activeFilters.includes("crowdview");
            }
          }
        }
      })
      .sort((name1, name2) =>
        sortStringsLikeHuman(parseMultiboxName(name1), parseMultiboxName(name2))
      );

    if (!sortAlphabetically) {
      return [...cps].reverse();
    }
    return [...cps];
  }, [activeFilters, sortAlphabetically]);

  useEffect(() => {
    if (controlPanels && Object.keys(controlPanels).length > 0) {
      const controlPanelNames = Object.keys(controlPanels);

      const crowdviewsExist = isAfterTwilioSoftShutoffDate
        ? false
        : controlPanelNames.some((name) => isCrowdViewControlPanel(name));
      const prescreensExist = controlPanelNames.some((name) =>
        isPrescreenControlPanel(name)
      );

      const options: string[] = ["transmission"];
      if (prescreensExist) {
        options.push("prescreen");
      }
      if (crowdviewsExist) {
        options.push("crowdview");
      }

      setActiveFilters(options);
      setHasControlPanels(controlPanelNames.length > 0);
      setHasCrowdViews(crowdviewsExist);
      setHasPrescreens(prescreensExist);
    } else {
      setActiveFilters(DEFAULT_FILTERS);
      setHasControlPanels(false);
      setHasCrowdViews(false);
      setHasPrescreens(false);
    }

    return () => {
      /* Do nothing */
    };
  }, [
    controlPanels,
    setActiveFilters,
    setHasControlPanels,
    setHasCrowdViews,
    setHasPrescreens,
  ]);

  return (
    <>
      {hasControlPanels && (
        <div className="layout-screeners-container">
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <FormControl
                sx={{ m: 1, maxWidth: 280, minWidth: 280 }}
                variant="standard"
              >
                <Select
                  //disabled={!hasCrowdViews && !hasPrescreens}
                  displayEmpty
                  label={filterAndSort.title}
                  renderValue={() => (
                    <InputLabel id="cp-select-label">
                      {filterAndSort.title}
                    </InputLabel>
                  )}
                  value={activeFilters}
                >
                  <MenuItem
                    value={"transmission"}
                    onClick={() => {
                      handleFilterChange("transmission");
                    }}
                  >
                    {`${filterAndSort.options["transmission"]}${
                      activeFilters.includes("transmission") ? " ✓" : ""
                    }`}
                  </MenuItem>
                  <MenuItem
                    disabled={!hasPrescreens}
                    value={"prescreen"}
                    onClick={() => {
                      handleFilterChange("prescreen");
                    }}
                  >
                    {`${filterAndSort.options["prescreen"]}${
                      activeFilters.includes("prescreen") ? " ✓" : ""
                    }`}
                  </MenuItem>
                  <MenuItem
                    disabled={!hasCrowdViews}
                    value={"crowdview"}
                    onClick={() => {
                      handleFilterChange("crowdview");
                    }}
                  >
                    {`${filterAndSort.options["crowdview"]}${
                      activeFilters.includes("crowdview") ? " ✓" : ""
                    }`}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TooltipButton
                color={"primary"}
                icon={
                  sortAlphabetically
                    ? ["fas", "sort-alpha-down"]
                    : ["fas", "sort-alpha-up"]
                }
                isDisabled={false}
                title={
                  sortAlphabetically
                    ? filterAndSort.sortButtonAlphabetical
                    : filterAndSort.sortButtonReverseAlphabetical
                }
                onClick={() => {
                  setSortAlphabetically(!sortAlphabetically);
                }}
              />
            </Grid>
          </Grid>

          <Box className="layout-screeners-screenerList">
            {visibleControlPanels.length > 0 && (
              <>
                {visibleControlPanels.map((controlPanelName, index) => {
                  return (
                    <>
                      <EndpointCard
                        controlPanel={controlPanels[controlPanelName] ?? {}}
                        currentRole={currentRole}
                        key={`endpoint_${index}`}
                        parentFuncs={parentFuncs}
                        roleChoices={roleChoices}
                      />
                      <div
                        key={`linebreak_${index}`}
                        style={{ margin: "1.5px" }}
                      />
                    </>
                  );
                })}
              </>
            )}
            {visibleControlPanels.length === 0 && (
              <Alert icon={false} severity="error">
                <b>{noEndpoints.text}</b>
              </Alert>
            )}
          </Box>
        </div>
      )}
      {!hasControlPanels && (
        <Alert icon={false} severity="error">
          <AlertTitle>
            <h4 style={{ margin: "auto" }}>{noEndpoints.title}</h4>
          </AlertTitle>
        </Alert>
      )}
    </>
  );
};

export default ControlPanelList;
