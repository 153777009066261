import type { FunctionComponent } from "react";

import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useHttpRequest, useSelector, useSnackbar } from "../../hooks";
import { branding } from "../../utilities/definitions";
import { nameValidationSchema } from "../../utilities/validators";
import LimitedBackdrop from "../../components/LimitedBackdrop";
import TextField from "../../components/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "ramda";
import Hidden from "../../components/Hidden";
import DuplicateParticipantsWarning from "../CreateParticipant/DuplicateParticipantsWarning";
import { StatusCodes } from "http-status-codes";

const validationSchema = yup.object().shape({
  fullName: nameValidationSchema.required(
    "A full name is required to save participant."
  ),
});

interface Props {
  participantConnected: boolean;
  participantGuid: string;
  networkDiagnosticState: {
    error: any;
    running: boolean;
  };
  endAssignment: () => void | void;
}

const SaveParticipantDialog: FunctionComponent<Props> = (props) => {
  const { participantGuid, endAssignment } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const [duplicateParticipantGuids, setDuplicateParticipantGuids] = useState<
    string[]
  >([]);
  const foundDuplicates = duplicateParticipantGuids.length > 0;

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: { fullName: "" },
    resolver: yupResolver(validationSchema),
  });

  const fullName = watch("fullName");

  async function handleClose() {
    await deleteParticipantRequest();
    setIsOpen(false);
    endAssignment();
    setDuplicateParticipantGuids([]);
  }

  async function onSubmit(data) {
    const { fullName } = data;

    await updateFullNameRequest({
      body: { fullName, saveTemporary: true },
    });
  }

  const submitHandler = handleSubmit(onSubmit);

  const [deleteParticipantRequest, deleteParticipantRequestState] =
    useHttpRequest(`admin_proxy/participants/${participantGuid}`, {
      method: "delete",
      searchParams: {
        accountGuid,
        productionGuid,
      },
      onError: () => {
        enqueueSnackbar(
          "Failed to delete Quick Participant. Contact support.",
          {
            variant: "error",
          }
        );
      },
      onSuccess: () => {
        enqueueSnackbar(`Quick Participant was deleted.`, {
          variant: "info",
        });
        setIsOpen(false);
      },
    });

  const [updateFullNameRequest, updateFullNameRequestState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/`,
    {
      method: "put",
      body: {
        accountGuid,
        productionGuid,
      },
      onError: async (error) => {
        enqueueSnackbar("Failed to save participant.", {
          variant: "error",
        });

        if (error?.response?.status === StatusCodes.CONFLICT) {
          const json = await error?.response?.json?.();
          const duplicates = json?.data?.duplicates;

          if (duplicates) {
            setDuplicateParticipantGuids(duplicates);
          }
        }
      },
      onSuccess: () => {
        enqueueSnackbar(`Participant was saved.`, {
          variant: "info",
        });

        endAssignment();
        setIsOpen(false);
      },
    }
  );

  const isLoading =
    deleteParticipantRequestState.loading || updateFullNameRequestState.loading;

  return (
    <Dialog open={isOpen} sx={{ minWidth: 350 }} onClose={handleClose}>
      <Hidden hide={!foundDuplicates}>
        <DialogTitle>Potential Duplicate Participant(s) Found</DialogTitle>

        <DuplicateParticipantsWarning
          duplicateParticipantGuids={duplicateParticipantGuids}
          handleCancelClick={() => setDuplicateParticipantGuids([])}
          handleProceedClick={async () => {
            await updateFullNameRequest({
              body: {
                bypassDuplicateCheck: true,
                fullName,
                saveTemporary: true,
              },
            });
          }}
        />
      </Hidden>

      <Hidden hide={foundDuplicates}>
        <DialogTitle>Save this contact (optional)</DialogTitle>
        <LimitedBackdrop open={isLoading} />
        <Grid container direction="column" spacing={1} sx={{ padding: 3 }}>
          <Grid item>
            <Typography variant="subtitle2">
              Enter a name for this participant to store them in{" "}
              {branding.stagedoor}.
            </Typography>
          </Grid>

          <Grid item>
            <TextField
              control={control}
              disabled={isLoading}
              errors={errors.fullName}
              fullWidth
              label={"Full Name"}
              name="fullName"
              placeholder={"ex., John Smith"}
            />
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={handleClose}>Skip and Remove Participant</Button>
          <Button
            disabled={!isEmpty(errors) || !fullName}
            onClick={submitHandler}
          >
            Save Participant
          </Button>
        </DialogActions>
      </Hidden>
    </Dialog>
  );
};

export default SaveParticipantDialog;
