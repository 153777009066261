import ky from "ky";
import { SERVER_ROOT } from "../../config";

export const getNextoMeetHostUrl = async (
  accountGuid: string,
  productionGuid: string,
  managerId: string
) => {
  try {
    if (accountGuid && productionGuid && managerId) {
      const res = await ky.get(
        `${SERVER_ROOT}/nextomeet/manager/${accountGuid}/${productionGuid}?managerId=${managerId}`
      );
      const data = await res.json();
      return data["url"] ?? undefined;
    } else {
      throw new Error("Missing required parameter");
    }
  } catch (e: any) {
    console.log("error getting nxtm host url");
    console.dir(e);
  }
};
