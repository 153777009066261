import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import CallButtonGroup from "./CallButtonGroup";
import { appList, branding } from "../../../utilities/definitions";
import { Grid } from "@mui/material";
import { useSelector } from "../../../hooks";

type Props = {
  currentEndpoint: any;
  deviceInfo: any;
  parentFuncs: any;
  participant?: any;
  recommendedApp?: string;
};

const CallButtons: FunctionComponent<Props> = (props) => {
  const {
    participant: assignedParticipant = {},
    currentEndpoint,
    deviceInfo,
    recommendedApp,
    parentFuncs,
  } = props;
  const {
    allocatedCMP,
    id: endpointID,
    inCall,
    waitingForCallStateChange,
  } = currentEndpoint;

  const handleCallClick = (appName: string) => {
    const chatApp = appName.toLowerCase();
    const isUsingCMP = allocatedCMP ? true : false;

    if (!inCall) {
      parentFuncs.startAndEndCall.startCall(
        {
          id: assignedParticipant.id,
          accountName: assignedParticipant[chatApp],
          chatApp,
          cmp: isUsingCMP ? { macAddress: allocatedCMP.macAddress } : false,
          endpointID,
        },
        isUsingCMP ? false : true // pass false for CMP, true for local
      );
    } else {
      parentFuncs.startAndEndCall.endCall(
        {
          id: assignedParticipant.id,
          chatApp,
          cmp: isUsingCMP ? { macAddress: allocatedCMP.macAddress } : false,
          endpointID,
        },
        isUsingCMP ? false : true
      );
    }
  };

  const configEnableZoom = useSelector(
    (state: any) => state.queue.clientConfiguration?.enableZoom
  );

  const zoomApiIsAvailable = useSelector(
    (state: any) => state.queue.showInfo?.zoomApiAvailability?.available
  );

  const nextomeetIsAvailable = false; /*useSelector(
    (state: any) => state.queue.showInfo?.nextomeetAvailable
  );*/

  const enableZoom = configEnableZoom;

  const [parsedAppList, setParsedAppList] = useState<string[]>([]);

  useEffect(() => {
    if (appList.length > 0) {
      let newAppList = appList;

      if (enableZoom) {
        if (!newAppList.includes("Zoom")) {
          newAppList.push("Zoom");
        }
      } else {
        if (newAppList.includes("Zoom")) {
          newAppList.filter((app) => app !== "Zoom");
        }
      }
      setParsedAppList(
        newAppList.sort((a, b) =>
          branding[a.toLowerCase()].localeCompare(branding[b.toLowerCase()])
        )
      );
    }
    return () => {
      setParsedAppList([]);
    };
  }, [appList, enableZoom, setParsedAppList]);

  return (
    <Grid container direction="column" spacing={1}>
      {parsedAppList.map((appName: string, index) => {
        const {
          connected,
          contacts,
          guid = "",
          email,
          phone_number,
          id,
          type,
        } = assignedParticipant;

        const isRecommendedApp = recommendedApp === appName;

        return (
          <CallButtonGroup
            appName={appName}
            callHandle={assignedParticipant[appName.toLowerCase()] ?? null}
            cmp={allocatedCMP ?? undefined}
            contacts={contacts}
            deviceInfo={deviceInfo}
            email={email} // Deprecated when Queue Participant logic updates to follow session
            endpointID={endpointID}
            guid={guid}
            handleCallClick={(appName: string) => handleCallClick(appName)}
            inCall={inCall}
            isParticipantConnected={connected}
            isRecommendedApp={isRecommendedApp}
            isWaitingForCallStateChange={waitingForCallStateChange ?? false}
            key={index}
            nextomeetIsAvailable={nextomeetIsAvailable}
            participantID={id} // Deprecated when Queue Participant logic updates to follow session
            participantType={type} // Deprecated when Queue Participant logic updates to follow session
            phoneNumber={phone_number}
            zoomApiIsAvailable={zoomApiIsAvailable}
          />
        );
      })}
      <a className="layout-hidden" id="startCall">
        click
      </a>
    </Grid>
  );
};

export default CallButtons;
