import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import * as R from "ramda";
import { Buffer } from "buffer";
import Typography from "@mui/material/Typography/Typography";
import { secondsToMilliseconds } from "date-fns";
import { useDateFormatter, useHttpRequest, useSelector } from "../../../hooks";
import CustomTooltip from "../../../components/Tooltip";

interface SnapshotData {
  buffer: Buffer;
  dateTime: Date;
}

type Props = {
  controlPanelName: string;
  id: string;
  small?: boolean;
};

// Convert buffers to arrays
const bufferToArray = (buffer) => Array.from(buffer);

// Compare two buffers using Ramda
const compareBuffers = (buffer1, buffer2) => {
  const array1 = bufferToArray(buffer1);
  const array2 = bufferToArray(buffer2);
  return R.equals(array1, array2);
};

const SnapshotDisplay: FunctionComponent<Props> = (props) => {
  const { controlPanelName, id, small = false } = props;
  const [snapshotData, setSnapshotData] = useState<SnapshotData | null>(null);

  const { productionGuid } = useSelector((state) => state.queue.showInfo);

  const formatDate = useDateFormatter();

  const [fetchSnapshot, fetchSnapshotStatus] = useHttpRequest(
    `admin_proxy/productions/${productionGuid}/snapshot`,
    {
      method: "get",
      searchParams: {
        controlPanelName: controlPanelName,
      },
    }
  );

  useEffect(() => {
    const fetchSnapshotData = async () => {
      try {
        const data = await fetchSnapshot();

        if (fetchSnapshotStatus.error) {
          throw new Error(
            fetchSnapshotStatus.error?.message ?? "Unknown Error"
          );
        }

        const currentBuffer: Buffer | undefined = snapshotData
          ? snapshotData.buffer
          : undefined;
        const incomingBuffer: Buffer | undefined = data?.buffer;

        if (!currentBuffer && incomingBuffer) {
          setSnapshotData(data);
        } else {
          if (incomingBuffer) {
            const bufferNotUpdated = compareBuffers(
              currentBuffer,
              incomingBuffer
            );
            if (!bufferNotUpdated) {
              setSnapshotData(data);
            }
          }
        }
      } catch (e: any) {
        console.error("Error fetching snapshot data:", e);
      }
    };

    fetchSnapshotData();

    const intervalId = setInterval(
      fetchSnapshotData,
      secondsToMilliseconds(20)
    );

    return () => clearInterval(intervalId);
  }, []);

  if (!snapshotData) {
    return null;
  }

  const { dateTime, buffer } = snapshotData;
  const parsedDateTime = formatDate(dateTime);

  const parsedBuffer = Buffer.from(buffer).toString("base64");
  const dataURI = `data:image/png;base64,${parsedBuffer}`;

  const captionText = small
    ? parsedDateTime
    : `Last capture: ${parsedDateTime}`;

  return (
    <CustomTooltip title={"Low-resolution preview (Not real-time)"}>
      <div
        id={`${id}-container`}
        style={{
          alignItems: "center",
          aspectRatio: "16/9",
          display: "flex",
          height: "auto",
          justifyContent: "center",
          position: "relative",
          width: small ? "55%" : "99%",
        }}
      >
        <img
          id={id}
          src={dataURI}
          style={{
            borderRadius: "5px",
            display: "block",
            height: "100%",
            width: "100%",
          }}
        />
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            bottom: 0,
            color: "#ffffff",
            margin: "3px",
            paddingLeft: "5px",
            paddingRight: "5px",
            position: "absolute",
          }}
        >
          <Typography
            sx={small ? { fontSize: "0.6rem !important" } : undefined}
            variant="caption"
          >
            {captionText}
          </Typography>
        </div>
      </div>
    </CustomTooltip>
  );
};

export default SnapshotDisplay;
