import type { ParticipantStatus } from "./definitions";

import { branding } from "./definitions";
import { ParticipantType } from "../store/types";
import { format as zoneFormat, utcToZonedTime } from "date-fns-tz";
import { formatDuration, hoursToMinutes, minutesToHours } from "date-fns";
import naturalCompare from "natural-compare-lite";

export const convertPhoneNumberToE164 = (phoneNumber: string) => {
  if (phoneNumber === "" || phoneNumber.length === 0) {
    return "";
  }
  let parsedPhoneValue = phoneNumber.replace(/[^0-9+]/g, "");
  if (parsedPhoneValue.indexOf("+") !== 0) {
    parsedPhoneValue = `+1${parsedPhoneValue}`;
  }
  return parsedPhoneValue;
};

export const toTitleCase = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const camelCaseToTitleCase = (str: string): string => {
  const result = str.replace(/([A-Z])/g, " $1");
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const formatProductionEventDuration = (duration: number): string => {
  let oneHourInMinutes = hoursToMinutes(1);

  if (duration / oneHourInMinutes > 1) {
    return formatDuration({
      hours: minutesToHours(duration),
      minutes: duration % oneHourInMinutes,
    });
  } else {
    return formatDuration({
      minutes: duration,
    });
  }
};

export const determineParticipantStatus = (
  participant: any
): ParticipantStatus => {
  const { qState, connected = false, assignedEndpoints = [] } = participant;

  const alreadyAssigned = assignedEndpoints.length > 0 ?? false;

  if (qState === "qCalling" && connected) {
    return "calling";
  } else if (qState === "qCalling" && !connected) {
    return "callingDisconnected";
  } else if (connected && alreadyAssigned) {
    return "connectedAssigned";
  } else if (connected && !alreadyAssigned) {
    return "connectedUnassigned";
  } else if (!connected && alreadyAssigned) {
    return "disconnectedAssigned";
  }
  return "disconnectedUnassigned";
};

export const getParticipantTypeBranding = (type: ParticipantType): string => {
  if (type === ParticipantType.QUEUE) {
    return "Queue";
  } else if (type === ParticipantType.STAGEDOOR) {
    return branding.stagedoor;
  }

  return "";
};

/**
 *
 * @param date Valid value to pass to `Date` constructor.
 * @param format Format based on Unicode Technical Standard #35.
 * @param timeZone Offset or IANA time zone name (e.g. "-05:00", "America/New_York").
 * @returns Date localized to timeZone parameter and stringified according to format.
 */
export const dateToFormattedString = (
  date: number | string | Date,
  format: string,
  timeZone: string
): string => {
  if (
    !(
      typeof date === "string" ||
      typeof date === "number" ||
      date instanceof Date
    )
  ) {
    return date;
  } else if (typeof format !== "string") {
    return format;
  } else if (typeof timeZone !== "string") {
    return timeZone;
  }

  const zonedDate = utcToZonedTime(date, timeZone);

  const dateString = zoneFormat(zonedDate, format, {
    timeZone: timeZone,
  });

  return dateString;
};

export const sortStringsLikeHuman = (a: string, b: string) => {
  return naturalCompare(a?.toLowerCase() ?? "", b?.toLowerCase() ?? "");
};

/* Utility functions break-down */
export const checkIfParticipantAlreadyAssignedToAMultibox = (
  assignedEndpoints: string[]
) => {
  return Boolean(
    assignedEndpoints.findIndex((endpoint) => endpoint.includes("multibox_"))
  );
};

export const compareNewPropsBasedOnState = (
  _state: any,
  _prevProps: any,
  _currentProps: any,
  _callback: any
) => {
  let stateNeedsUpdate = false;
  if (_state) {
    if (_state.name) {
      console.log(`Checking if ${_state.name} needs to update`);
    }
    let statePropertiesToUpdate = {};

    for (var key in _state) {
      if (
        JSON.stringify(_prevProps[key]) !== JSON.stringify(_currentProps[key])
      ) {
        statePropertiesToUpdate[key] = _currentProps[key];
        stateNeedsUpdate = true;
      }
    }

    if (stateNeedsUpdate) {
      _callback(statePropertiesToUpdate);
    }
  }
};

export const parseMultiboxName = (_endpointName: any) => {
  if (typeof _endpointName === "string") {
    if (_endpointName.includes("multibox_")) {
      let firstTrim = _endpointName.replace("multibox_", "");
      let trimmedFormat = firstTrim.substring(
        firstTrim.indexOf("_layout") + 7,
        firstTrim.length
      );
      let trimmedName = firstTrim.substring(0, firstTrim.indexOf("_layout"));
      return ` ${branding.crowdview}: ${trimmedName} (${trimmedFormat})`;
    } else {
      return _endpointName;
    }
  } else {
    return "";
  }
};

export const sanitizeRoomName = (roomName: any) => {
  return roomName?.replaceAll(/[^a-z0-9@.+]/gi, "");
};
