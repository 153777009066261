import type { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";
import { Dangerous, Warning } from "@mui/icons-material";
import {
  ParticipantVpnDetermination,
  ParticipantStatus,
  participantStatusMuiColors,
  participantStatusText,
} from "../utilities/definitions";
import CustomTooltip from "./Tooltip";

const useStyles = makeStyles<any>((_theme) => ({
  statusChip: {
    marginLeft: 10,
  },
}));

interface Props {
  isVpn?: ParticipantVpnDetermination;
  status: ParticipantStatus;
}

const ParticipantStatusChip: FunctionComponent<Props> = (props) => {
  const { isVpn = "unknown", status } = props;
  const classes = useStyles(props);

  const chipShouldDisplay = status && status !== "disconnectedUnassigned";

  const baseLabelText = participantStatusText[status];

  const shouldUpdateVpnStatus = isVpn === "possible" || isVpn === "yes";

  const tooltipText = shouldUpdateVpnStatus
    ? `**Guest  ${
        isVpn === "yes" ? "is" : "is possibly"
      } using a VPN connection, which can cause poor quality connections**`
    : `Guest status: ${baseLabelText}`;

  return chipShouldDisplay ? (
    <CustomTooltip title={tooltipText}>
      <Chip
        className={classes.statusChip}
        color={participantStatusMuiColors[status]}
        icon={
          shouldUpdateVpnStatus ? (
            isVpn === "yes" ? (
              <Dangerous />
            ) : (
              <Warning />
            )
          ) : undefined
        }
        label={baseLabelText}
      />
    </CustomTooltip>
  ) : null;
};

export default ParticipantStatusChip;
