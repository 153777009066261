import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import Table from "../../components/Table";
import TooltipButton from "../../components/TooltipButton";
import { useDateFormatter, useHttpRequest } from "../../hooks";
import {
  branding,
  friendlyEventLogTypeNames,
  ParticipantSessionEventLogType,
} from "../../utilities/definitions";
import TabPanel from "../../components/TabPanel";
import {
  camelCaseToTitleCase,
  sortStringsLikeHuman,
  toTitleCase,
} from "../../utilities/utilityFunctions";
import { removeObjectProperties } from "../../utilities/removeObjectProperties";

const parseFriendlyDeviceInfo = (deviceInfo: any) => {
  const {
    browserName,
    browserVersion,
    deviceOs,
    deviceOsVersion,
    deviceType,
    facetimeCompatible,
    webrtcCompatible,
  } = deviceInfo;

  function parseOsName(osName: string) {
    if (osName) {
      let p = toTitleCase(osName);

      if (p === "MacOS") {
        return "macOS";
      } else if (p === "Ios") {
        return "iOS";
      }

      return p;
    }

    return osName;
  }

  return `Device Type: ${toTitleCase(deviceType) ?? "Unknown"} | Device OS: ${
    deviceOs ? parseOsName(deviceOs) : "Unknown"
  }${
    deviceOs === "macos" ? "" : ` ${deviceOsVersion ?? "Unknown"}`
  } | Browser Name: ${
    toTitleCase(browserName) ?? "Unknown"
  } | Browser Version: ${
    browserVersion ?? "Unknown"
  } | FaceTime Compatible: ${toTitleCase(
    facetimeCompatible.toString()
  )} | WebRTC Compatible: ${toTitleCase(webrtcCompatible.toString())}`;
};

const parseFriendlyDiagnosticReport = (diagnostic: any) => {
  const { bitrate, jitter, packetLoss, rtt, score } = diagnostic;
  return `Bitrate: ${bitrate ?? "Unknown"} | Jitter: ${
    jitter ?? "Unknown"
  } | Packet Loss: ${packetLoss ?? "Unknown"} | RTT: ${
    rtt ?? "Unknown"
  } | Score: ${score ?? "Unknown"}`;
};

const parseSpecialData = (type: string, data: any) => {
  if (type === ParticipantSessionEventLogType.Diagnostic) {
    return parseFriendlyDiagnosticReport(data);
  }
  if (type === ParticipantSessionEventLogType.Device) {
    return parseFriendlyDeviceInfo(data);
  }
  if (type === ParticipantSessionEventLogType.Other) {
    const event = data.info;
    if (event === "reconnected") {
      return "Reconnected while in call";
    } else if (event === "abandoned") {
      return "Call potentially abandoned";
    } else {
      return data.info;
    }
  }
  return data;
};

type Props = {
  participantGuid: string;
  session?: any;
};

const SessionEventLogsTab: FunctionComponent<Props> = (props) => {
  const { participantGuid, session } = props;
  const sessionGuid = session?.guid;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabSelection = (_event: any, newTab: number): void => {
    setSelectedTab(newTab);
  };

  const [requestAllSessionEventLogs, requestAllSessionEventLogsState] =
    useHttpRequest(`participants/${participantGuid}/event_logs`, {
      admin: true,
      method: "get",
    });

  const [requestEventLogsForSession, requestEventLogsForSessionState] =
    useHttpRequest(
      `participants/${participantGuid}/sessions/${sessionGuid}/event_logs`,
      {
        admin: true,
        method: "get",
      }
    );

  useEffect(() => {
    requestAllSessionEventLogs();

    if (sessionGuid) {
      requestEventLogsForSession();
      setSelectedTab(0);
    } else {
      setSelectedTab(1);
    }
  }, [requestAllSessionEventLogs, requestEventLogsForSession, sessionGuid]);

  const allEventLogs = requestAllSessionEventLogsState.data ?? [];
  const sessionEventLogs = requestEventLogsForSessionState.data ?? [];

  return (
    <Grid className="fullWidth" container direction="column" item spacing={1}>
      <Grid item>
        <Tabs value={selectedTab} onChange={handleTabSelection}>
          <Tab disabled={!sessionGuid} label="Current Session" value={0} />
          <Tab label="All Sessions" value={1} />
        </Tabs>

        <TabPanel index={0} value={selectedTab}>
          <Table
            columns={[
              { id: "type", label: "Event Type" },
              { id: "data", label: "Event Data", sortable: false },
              { id: "createdAt", label: "Timestamp" },
            ]}
            data={sessionEventLogs}
            initialOrder="desc"
            initialOrderByProperty="createdAt"
            initialRowsPerPage={10}
            rowRenderer={({ rowItem }) => (
              <EventLogTableRow rowItem={rowItem} />
            )}
            rowsPerPageOptions={[10, 15, 20]}
            style={{ width: "100%" }}
            toolbarChildren={
              requestEventLogsForSessionState.loading ? (
                <CircularProgress />
              ) : (
                <TooltipButton
                  icon={["fas", "arrows-rotate"]}
                  size="small"
                  text="Sync"
                  title={"Retrieve the most up-to-date information"}
                  onClick={() => requestEventLogsForSession()}
                />
              )
            }
          />
        </TabPanel>

        <TabPanel index={1} value={selectedTab}>
          <Table
            columns={[
              { id: "type", label: "Event Type" },
              { id: "data", label: "Event Data", sortable: false },
              { id: "createdAt", label: "Timestamp" },
            ]}
            data={allEventLogs}
            initialOrder="desc"
            initialOrderByProperty="createdAt"
            initialRowsPerPage={10}
            rowRenderer={({ rowItem }) => (
              <EventLogTableRow rowItem={rowItem} />
            )}
            rowsPerPageOptions={[10, 15, 20]}
            style={{ width: "100%" }}
            toolbarChildren={
              requestAllSessionEventLogsState.loading ? (
                <CircularProgress />
              ) : (
                <TooltipButton
                  icon={["fas", "arrows-rotate"]}
                  size="small"
                  text="Sync"
                  title={"Retrieve the most up-to-date information"}
                  onClick={() => requestAllSessionEventLogs()}
                />
              )
            }
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

const EventLogTableRow: FunctionComponent<{ rowItem: any }> = ({ rowItem }) => {
  const { createdAt, data, type } = rowItem;

  const formatDate = useDateFormatter();

  const friendlyTypeName = friendlyEventLogTypeNames[type];

  const needsSpecialParsing =
    type === ParticipantSessionEventLogType.Device ||
    type === ParticipantSessionEventLogType.Diagnostic ||
    type === ParticipantSessionEventLogType.Other;

  const parsedData = needsSpecialParsing
    ? removeObjectProperties(data, ["guid", "createdAt", "modifiedAt"])
    : data;

  const friendlyData = needsSpecialParsing
    ? parseSpecialData(type, parsedData)
    : Object.entries(parsedData)
        .sort((a, b) => {
          return sortStringsLikeHuman(a[0], b[0]);
        })
        .reduce<string>((curr, [key, value]) => {
          const friendlyKey = camelCaseToTitleCase(key);
          let friendlyValue = value as any;

          if (
            type === ParticipantSessionEventLogType.CallEnded ||
            type === ParticipantSessionEventLogType.CallStarted
          ) {
            friendlyValue = friendlyValue
              ?.replace("facetime", branding.facetime)
              ?.replace("gruveo", branding.gruveo)
              ?.replace("nextomeet", branding.nextomeet)
              ?.replace("twilio", branding.twilio)
              ?.replace("zoom", branding.zoom);
          } else if (
            type === ParticipantSessionEventLogType.InvitationSent &&
            key === "contacts"
          ) {
            return curr;
          }

          const pair = `${friendlyKey}: ${friendlyValue}`;

          return curr ? `${curr}, ${pair}` : pair;
        }, "");

  return (
    <TableRow>
      <TableCell>{friendlyTypeName}</TableCell>
      <TableCell>{friendlyData}</TableCell>
      <TableCell>{formatDate(createdAt)}</TableCell>
    </TableRow>
  );
};

export default SessionEventLogsTab;
