import type { FunctionComponent } from "react";
import { Badge } from "@mui/material";
import MenuButton from "../MenuButton";
import {
  sortStringsLikeHuman,
  parseMultiboxName,
} from "../../utilities/utilityFunctions";
import { ParticipantType } from "../../store/types";
import * as templates from "./templates";
import { isAfterTwilioSoftShutoffDate } from "../../utilities/definitions";

type Props = {
  currentEndpointId: string;
  disabled?: boolean;
  endpoints: Record<string, any>;
  isParticipantAssigned: boolean;
  isUserFullscreener: boolean;
  isUserPrescreener: boolean;
  participantType: ParticipantType;
  handleAssignmentClick: (endpoint: any) => void;
};

const SelectEndpoint: FunctionComponent<Props> = (props) => {
  const {
    currentEndpointId,
    disabled = false,
    endpoints: controlPanels,
    isParticipantAssigned,
    isUserFullscreener,
    isUserPrescreener,
    participantType,
    handleAssignmentClick,
  } = props;

  const parsedControlPanels = isAfterTwilioSoftShutoffDate
    ? Object.values(controlPanels).filter(
        (controlPanel) => controlPanel.name.includes("multibox_") === false
      )
    : Object.values(controlPanels);

  const availableControlPanels = parsedControlPanels
    .filter((controlPanel) => {
      const { assignedCaller, name } = controlPanel;
      const isControlPanelCrowdView = name.includes("multibox_");

      if (
        name === currentEndpointId ||
        (!isControlPanelCrowdView && assignedCaller)
      ) {
        return false;
      }

      if (isControlPanelCrowdView) {
        return (
          participantType === ParticipantType.QUEUE ||
          participantType === ParticipantType.STAGEDOOR
        );
      }

      return true;
    })
    .sort((controlPanel1, controlPanel2) => {
      return sortStringsLikeHuman(
        parseMultiboxName(controlPanel1.name),
        parseMultiboxName(controlPanel2.name)
      );
    });

  return (
    <>
      {(isUserPrescreener || isUserFullscreener) && (
        <Badge
          badgeContent={availableControlPanels.length}
          color="secondary"
          variant="standard"
        >
          <MenuButton
            color="warning"
            disabled={isParticipantAssigned || disabled}
            items={availableControlPanels.map((controlPanel, index) => ({
              children: parseMultiboxName(controlPanel.name),
              key: index,
              onClick: () => handleAssignmentClick(controlPanel),
            }))}
            text={templates.selectEndpointButton.text}
            title={
              disabled && !isParticipantAssigned
                ? templates.selectEndpointButton.tooltip.notInvited
                : isParticipantAssigned
                ? templates.selectEndpointButton.tooltip.assigned
                : templates.selectEndpointButton.tooltip.notAssigned
            }
          />
        </Badge>
      )}
    </>
  );
};

export default SelectEndpoint;
