import type { ChangePageAction } from "./actions";

//auth
export const SET_HOSTED_NETWORKS = "SET_HOSTED_NETWORKS";
export const STORE_FORCED_LOGOUT_RECOVERY_DATA =
  "STORE_FORCED_LOGOUT_RECOVERY_DATA";
export const SET_ROLE_CHOICES = "SET_ROLE_CHOICES";
export const SET_USER_INFO = "SET_USER_INFO";
export const SELECT_ROLE = "SELECT_ROLE";
export const SELECT_NETWORK = "SELECT_NETWORK";
export const ON_SOCKET_CONNECT = "ON_SOCKET_CONNECT";
export const CHANGE_PAGE = "CHANGE_PAGE";

//endpoint
export const ALLOCATE_CMP = "ALLOCATE_CMP";
export const CMP_LIST = "CMP_LIST";
export const PARTICIPANT_EAVESDROP_STATUS = "PARTICIPANT_EAVESDROP_STATUS";
export const PROGRAM_EAVESDROP_STATUS = "PROGRAM_EAVESDROP_STATUS";
export const PROGRAM_CMP = "PROGRAM_CMP";

//queue
export const ADD_QUEUE_PARTICIPANT = "ADD_QUEUE_PARTICIPANT";
export const NEW_CALLER = "NEW_CALLER";
export const REMOVE_QUEUE_PARTICIPANT = "REMOVE_QUEUE_PARTICIPANT";
export const SELECT_SCHEDULE_RANGE = "SELECT_SCHEDULE_RANGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SORT_MODE = "SET_SORT_MODE";
export const SET_ZIP = "SET_ZIP";
export const TOGGLE_AUDIO = "TOGGLE_AUDIO";
export const UPDATE_QUEUE_PARTICIPANT = "UPDATE_QUEUE_PARTICIPANT";

//session
export const SHOW_INFO = "SHOW_INFO";
export const SERVER_OFFSET = "SERVER_OFFSET";
export const HOUSEKEEPING = "HOUSEKEEPING";
export const HOUSEKEEPING_ACK = "HOUSEKEEPING_ACK";

export const CRITICAL_ERROR = "CRITICAL_ERROR";
export const SERVER_MESSAGE = "SERVER_MESSAGE";
export const ZOOM_AVAILABILITY_CHANGE = "ZOOM_AVAILABILITY_CHANGE";
export const CALLERS = "CALLERS";
export const EPISODE_INFO = "EPISODE_INFO";
export const KICKED_BY_PRODUCER = "KICKED_BY_PRODUCER";
export const SELECT_SHOW = "SELECT_SHOW";
export const SHOWS = "SHOWS";
export const ZIP_ERROR = "ZIP_ERROR";
export const ZOOM_HOST_URL = "ZOOM_HOST_URL";
export const ZOOM_SUPPRESS_PRESCREEN_WARNING =
  "ZOOM_SUPPRESS_PRESCREEN_WARNING";
export const NEXTOMEET_PRODUCER_URL = "NEXTOMEET_PRODUCER_URL";

export const SET_CONTROL_PANELS = "SET_CONTROL_PANELS";
export const CONTROL_PANEL_UPDATED = "CONTROL_PANEL_UPDATED";

export const SET_PARTICIPANTS = "QUEUE_SET_PARTICIPANTS";
export const PARTICIPANT_CREATED = "QUEUE_PARTICIPANT_CREATED";
export const PARTICIPANT_DELETED = "QUEUE_PARTICIPANT_DELETED";
export const PARTICIPANT_UPDATED = "QUEUE_PARTICIPANT_UPDATED";

export const SET_PRODUCTION_EVENTS = "SET_PRODUCTION_EVENTS";
export const PRODUCTION_EVENT_CREATED = "PRODUCTION_EVENT_CREATED";
export const PRODUCTION_EVENT_DELETED = "PRODUCTION_EVENT_DELETED";
export const PRODUCTION_EVENT_UPDATED = "PRODUCTION_EVENT_UPDATED";
export const PARTICIPANT_ADDED_TO_PRODUCTION_EVENT =
  "PARTICIPANT_ADDED_TO_PRODUCTION_EVENT";
export const PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT =
  "PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT";

export const SET_CLIENT_CONFIGURATION = "QUEUE_SET_CLIENT_CONFIGURATION";

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

interface SetClientConfigurationAction {
  type: typeof SET_CLIENT_CONFIGURATION;
  payload: any;
}

export type QueueActionTypes = ChangePageAction | SetClientConfigurationAction;
