import { createAction } from "@reduxjs/toolkit";
import {
  QueueActionTypes,
  CHANGE_PAGE,
  CONTROL_PANEL_UPDATED,
  PARTICIPANT_CREATED,
  PARTICIPANT_DELETED,
  PARTICIPANT_UPDATED,
  SET_CLIENT_CONFIGURATION,
  SET_PARTICIPANTS,
  PRODUCTION_EVENT_CREATED,
  PRODUCTION_EVENT_DELETED,
  PRODUCTION_EVENT_UPDATED,
  SET_PRODUCTION_EVENTS,
  PARTICIPANT_ADDED_TO_PRODUCTION_EVENT,
  PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT,
  SET_CONTROL_PANELS,
  ON_SOCKET_CONNECT,
  SELECT_NETWORK,
  STORE_FORCED_LOGOUT_RECOVERY_DATA,
  SELECT_ROLE,
  SET_USER_INFO,
  SET_ROLE_CHOICES,
  ALLOCATE_CMP,
  CMP_LIST,
  PARTICIPANT_EAVESDROP_STATUS,
  PROGRAM_EAVESDROP_STATUS,
  PROGRAM_CMP,
  TOGGLE_AUDIO,
  SET_FILTERS,
  SET_ZIP,
  SHOW_INFO,
  SERVER_OFFSET,
  SELECT_SCHEDULE_RANGE,
  DateRange,
  HOUSEKEEPING,
  HOUSEKEEPING_ACK,
  CRITICAL_ERROR,
  ZOOM_HOST_URL,
  ZOOM_SUPPRESS_PRESCREEN_WARNING,
  NEXTOMEET_PRODUCER_URL,
  SERVER_MESSAGE,
} from "./types";
import { withPayloadType } from "../helpers";

export const changePage = createAction(
  CHANGE_PAGE,
  withPayloadType<{
    newPage: string;
    screenerName?: string;
  }>()
);

export const setControlPanels = createAction(
  SET_CONTROL_PANELS,
  withPayloadType<{
    controlPanels: any;
    episode: any;
  }>()
);

export const controlPanelUpdated = createAction(
  CONTROL_PANEL_UPDATED,
  withPayloadType<{
    controlPanel: any;
  }>()
);

export const setParticipants = createAction(
  SET_PARTICIPANTS,
  withPayloadType<{ participants: any[] }>()
);

export const participantCreated = createAction(
  PARTICIPANT_CREATED,
  withPayloadType<{
    participant: any;
  }>()
);

export const participantDeleted = createAction(
  PARTICIPANT_DELETED,
  withPayloadType<{ participantGuid: string }>()
);

export const participantUpdated = createAction(
  PARTICIPANT_UPDATED,
  withPayloadType<{ participant: any }>()
);

export const setProductionEvents = createAction(
  SET_PRODUCTION_EVENTS,
  withPayloadType<{ productionEvents: any[] }>()
);

export const productionEventCreated = createAction(
  PRODUCTION_EVENT_CREATED,
  withPayloadType<{ productionEvent: any }>()
);

export const productionEventDeleted = createAction(
  PRODUCTION_EVENT_DELETED,
  withPayloadType<{ productionEventGuid: string }>()
);

export const productionEventUpdated = createAction(
  PRODUCTION_EVENT_UPDATED,
  withPayloadType<{ productionEvent: any }>()
);

export const participantAddedToProductionEvent = createAction(
  PARTICIPANT_ADDED_TO_PRODUCTION_EVENT,
  withPayloadType<{ productionEventGuid: string; participantGuid: string }>()
);

export const participantRemovedFromProductionEvent = createAction(
  PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT,
  withPayloadType<{ productionEventGuid: string; participantGuid: string }>()
);

export const selectScheduleRange = createAction(
  SELECT_SCHEDULE_RANGE,
  withPayloadType<{ range: DateRange }>()
);

export const setClientConfiguration = (data: any): QueueActionTypes => ({
  type: SET_CLIENT_CONFIGURATION,
  payload: data,
});

//auth
export const storeForcedLogoutRecoveryData = () => ({
  type: STORE_FORCED_LOGOUT_RECOVERY_DATA,
});

export const onSocketConnect = () => {
  return {
    type: ON_SOCKET_CONNECT,
  };
};

export const selectNetwork = (network: any) => {
  return {
    type: SELECT_NETWORK,
    payload: network,
  };
};

export const selectRole = (role: any) => {
  return {
    type: SELECT_ROLE,
    payload: role,
  };
};

export const setUserInfo = (userInfo: any) => {
  return {
    type: SET_USER_INFO,
    payload: userInfo,
  };
};

export const setRoleChoices = (roleChoices: any) => {
  return {
    type: SET_ROLE_CHOICES,
    payload: roleChoices,
  };
};

//endpoint
export const allocateCMP = (cmp: any) => {
  return {
    type: ALLOCATE_CMP,
    payload: cmp,
  };
};

export const cmpList = (cmpList: any) => {
  return {
    type: CMP_LIST,
    payload: cmpList,
  };
};

export const setParticipantEavesDropStatus = (data: any) => {
  return {
    type: PARTICIPANT_EAVESDROP_STATUS,
    payload: data,
  };
};

export const setProgramEavesDropStatus = (data: any) => {
  return {
    type: PROGRAM_EAVESDROP_STATUS,
    payload: data,
  };
};

export const setProgramCMP = (data: any) => {
  return {
    type: PROGRAM_CMP,
    payload: data,
  };
};

//queue
export const toggleAudio = () => {
  return {
    type: TOGGLE_AUDIO,
  };
};

export const setFilters = (_filtersAnd, _filtersOr) => {
  return {
    type: SET_FILTERS,
    payload: {
      filtersAnd: _filtersAnd,
      filtersOr: _filtersOr,
    },
  };
};

export const setNewTargetZip = (_postalCode) => {
  return {
    type: SET_ZIP,
    payload: _postalCode,
  };
};

//session
export const setShowInfo = (showInfo) => {
  return {
    type: SHOW_INFO,
    payload: showInfo,
  };
};

export const setServerOffset = (serverOffset) => {
  return {
    type: SERVER_OFFSET,
    payload: serverOffset,
  };
};

export const housekeeping_performed = () => {
  return {
    type: HOUSEKEEPING,
    payload: {},
  };
};

export const housekeeping_acknowledged = () => {
  return {
    type: HOUSEKEEPING_ACK,
    payload: {},
  };
};

export const critical_error = (message: string | null) => {
  return {
    type: CRITICAL_ERROR,
    payload: { message },
  };
};

export const setServerMessage = (message: string | null) => {
  return {
    type: SERVER_MESSAGE,
    payload: { message },
  };
};

export const zoomHostUrl = (hostUrl) => {
  return {
    type: ZOOM_HOST_URL,
    payload: { hostUrl },
  };
};

export const zoomHideWarning = () => {
  return {
    type: ZOOM_SUPPRESS_PRESCREEN_WARNING,
    payload: {},
  };
};

export const nextomeetProducerUrl = (producerUrl) => {
  return {
    type: NEXTOMEET_PRODUCER_URL,
    payload: { producerUrl },
  };
};

export type ChangePageAction = ReturnType<typeof changePage>;
