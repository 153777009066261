import { Fragment, useMemo } from "react";
import { Badge } from "@mui/material";
import {
  sortStringsLikeHuman,
  parseMultiboxName,
} from "../../utilities/utilityFunctions";
import {
  isAfterTwilioSoftShutoffDate,
  participantTypeEnums,
} from "../../utilities/definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { determineCrowdviewSeatsAvailable } from "../../utilities/crowdViewStatus";
import MenuButton from "../../components/MenuButton";
import { useSocket } from "../../hooks";

const MoveParticipantButton = (props) => {
  const { currentScreener, screeners: controlPanels, size = undefined } = props;
  const { assignedCaller, inCall } = currentScreener;

  const socketService = useSocket();

  function handleClick(controlPanelName) {
    socketService.emit("moveParticipantToEndpoint", {
      endpointToTransferTo: controlPanelName,
    });
  }

  const isManualParticipant =
    assignedCaller?.type === participantTypeEnums.MANUAL;

  const isQuickParticipant = assignedCaller?.temporary;

  const parsedEndpoints = isAfterTwilioSoftShutoffDate
    ? Object.values(controlPanels).filter(
        (controlPanel) => controlPanel.name.includes("multibox_") === false
      )
    : Object.values(controlPanels);

  const availableControlPanels = parsedEndpoints
    .filter((controlPanel) => controlPanel.name !== currentScreener.name)
    .sort((controlPanel1, controlPanel2) =>
      sortStringsLikeHuman(
        parseMultiboxName(controlPanel1.name),
        parseMultiboxName(controlPanel2.name)
      )
    );

  const availableControlPanelsCount = availableControlPanels.length;
  const popoverTitle = useMemo(() => {
    if (isQuickParticipant) {
      return "Cannot move a Quick Participant; End Assignment and save them first";
    }
    if (inCall) {
      return "Cannot change a participant's assignment while in call";
    }
    if (availableControlPanelsCount > 0) {
      return `Move participant to an alternate Control Panel (${availableControlPanelsCount} available)`;
    }
    return "No Control Panels available";
  }, [availableControlPanelsCount, inCall, isQuickParticipant]);

  return (
    <MenuButton
      color="primary"
      disabled={isQuickParticipant || inCall}
      items={availableControlPanels.map((controlPanel) => {
        const screenerIsCrowdView = controlPanel.name.includes("multibox_");
        const noCurrentParticipant = screenerIsCrowdView
          ? true
          : controlPanel.assignedCaller === false;
        const callerCannotBeAssignedToCrowdView =
          assignedCaller.type === participantTypeEnums.MANUAL;

        const shouldDisableControlPanelOption =
          isManualParticipant ||
          !noCurrentParticipant ||
          (screenerIsCrowdView && callerCannotBeAssignedToCrowdView);

        const notEnabledText = shouldDisableControlPanelOption
          ? isManualParticipant ||
            (screenerIsCrowdView && callerCannotBeAssignedToCrowdView)
            ? ` - ${assignedCaller.type} Participants ${
                isManualParticipant
                  ? "Cannot be Transferred"
                  : "Are Incompatible with Control Panel"
              }`
            : " - Already Has Participant"
          : " - Available";

        const availableSeats = controlPanel.multibox?.seatStatus
          ? determineCrowdviewSeatsAvailable(controlPanel.multibox.seatStatus)
          : -1;

        const className = `move-participant-button-item ${
          shouldDisableControlPanelOption ? "color-light-gray" : ""
        }`;

        return {
          children: (
            <Fragment>
              <span className="endpoint-info">
                {`${parseMultiboxName(controlPanel.name)}${notEnabledText}`}
              </span>
              {controlPanel.multibox && (
                <Badge
                  badgeContent={availableSeats}
                  color="secondary"
                  invisible={availableSeats === -1}
                >
                  <FontAwesomeIcon icon={["fas", "th-large"]} />
                </Badge>
              )}
            </Fragment>
          ),
          className,
          disabled: shouldDisableControlPanelOption,
          key: controlPanel.id,
          onClick: () => {
            handleClick(controlPanel.name);
          },
        };
      })}
      size={size}
      text="Transfer Participant"
      title={popoverTitle}
    />
  );
};

export default MoveParticipantButton;
