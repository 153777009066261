import { FunctionComponent } from "react";
import { Box } from "@mui/material";

type Props = {
  index: number;
  style?: React.CSSProperties;
  value: number;
};

const TabPanel: FunctionComponent<Props> = (props) => {
  const { children, index, style = undefined, value } = props;

  return (
    <div hidden={index !== value} role="tabpanel" style={style}>
      {index === value && <Box className="tab-panel">{children}</Box>}
    </div>
  );
};

export default TabPanel;
